import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { AuthInterceptorService } from './auth-interceptor.service';
import { PasswordRecoveryService } from './password-recovery.service';

export const services = [AuthService, UserService, AuthInterceptorService, PasswordRecoveryService];

export * from './auth.service';
export * from './user.service';
export * from './auth-interceptor.service';
export * from './password-recovery.service';
