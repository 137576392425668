import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PlansPaymentMethod } from '@common/models';
import { ToastService } from '@common/services/toast.service';
import { CommerceService } from '@common/services/commerce.service';
import { PlansService } from '@common/services/plans.service';
import { NgbDateStruct, NgbDate, NgbDateParserFormatter, NgbCalendar, NgbModal, NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


const initialPayData:any = {
    billPlanId: '',
    paymentDate: '',
    paymentMethod: '',
    ref: '',
    amount: 0,
    amountPlan: 0,
    amountVES: 0,
    exchangeRateBCV: 0,
    issuing: '',
    comments: ''
}

@Component({
    selector: 'fina-form-pay-plan',
    templateUrl: './form-pay-plan.component.html',
    styleUrls: ['form-pay-plan.component.scss'],
})
export class FormPayPlanComponent implements OnInit {

    @Input() isPlan: boolean = false;
    @ViewChild("payData") payData: TemplateRef<any>;

    payFormData: any = {...initialPayData};
    loading: boolean = false;
    paymentMethods: PlansPaymentMethod[] = [];
    paymentMethodSelected: PlansPaymentMethod = null;

    // fechas
    hoveredDate: NgbDate | null = null;
	fromDate: NgbDate;
	toDate: NgbDate | null = null;
    model: NgbDateStruct;
    indexSelected: number = 0;

    constructor( public modalService: NgbModal,
        public plansService: PlansService,
        private toastService: ToastService,
        private calendar: NgbCalendar,
        public formatter: NgbDateParserFormatter,
        public commerceService: CommerceService,
    ) {
        this.fromDate = new NgbDate(calendar.getToday().year, calendar.getToday().month, calendar.getToday().day);
    }

    ngOnInit() {
        this.getPlans()
        this.getPaymentMethods()
    }

    getPlans() {
        const amountPlan = Number(this.plansService.plansBundle$?.value.currentBillplan.amountPlan.toFixed(2))
        const amountToPay = Number(this.plansService.plansBundle$?.value.currentBillplan.amount.toFixed(2))
        const exchangeRateBCV = Number(this.commerceService.exchangeRateBCV$?.getValue()?.toFixed(2))
        const amountVES = Number((amountToPay * exchangeRateBCV).toFixed(2))
        this.payFormData = {
            ...this.payFormData,
            billPlanId: this.plansService.plansBundle$?.value.currentBillplan._id,
            amountPlan,
            amount: amountToPay,
            amountVES: amountVES,
            exchangeRateBCV: exchangeRateBCV,
            paymentDate: this.formatter.format(this.fromDate)
        }
    }

    getPaymentMethods() {
        this.plansService.getPaymentMethods$().subscribe({
            next: (res: any) => {
                this.paymentMethods = res.data;
                this.paymentMethodSelected = res?.data?.length > 0 ? res.data[0] : null;

                this.payFormData = {
                    ...this.payFormData,
                    paymentMethod: res?.data?.length > 0 ? res.data[0]._id : ''
                }
            },
            error: (err: any) => {
                this.toastService.error(err.error.title, err.error.message);
            }
        })
    }

    payPlan(){
        this.loading = true;
        this.plansService.payPlan$(this.payFormData).subscribe({
            next: (res: any) => {
                const currentPlansBundle = this.plansService.plansBundle$.value;
                const newPlansBundle = { ...currentPlansBundle, nextPayment: res.data.nextPayment, paidDates: res.data.paidDates, isInProcess: res.data.isInProcess, currentBillplan: res.data.currentBillplan, billsplan: res.data.billsplan};
                this.plansService.plansBundle$.next(newPlansBundle);
                this.toastService.success(res.title, res.message);
                this.modalService.dismissAll();
                this.clearform();
                this.loading = false;
            },
            error: (err: any) => {
                this.loading = false;
                this.toastService.error(err.error.title, err.error.message);
            }
        })
    }

    onChangePayment(id:string, index:number){
        this.payFormData = {
            ...this.payFormData,
            paymentMethod: id
        }
        this.indexSelected = index;
        const selected:PlansPaymentMethod = this.paymentMethods.filter((pm:any) => pm._id == this.payFormData.paymentMethod)[0]
        this.paymentMethodSelected = selected;

        // this.modalService.open(this.payData, { size: 'sm', centered: true, backdrop : 'static', keyboard : false,});
    }

    clearform(){
        this.payFormData = {...initialPayData};
    }

    open(content: TemplateRef<any>, modalOptions: NgbModalOptions = {}) {
        this.modalService.open(content, modalOptions).result;
    }

    _getDismissReason(reason: unknown): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    // Date
    onDateSelection(date: NgbDate, datepicker: any) {
        this.fromDate = date;
        datepicker.close();

        const fromDateToSend = this.formatter.format(this.fromDate);

        this.payFormData = {
            ...this.payFormData,
            paymentDate: fromDateToSend,
        }
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input);
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
	}
}
