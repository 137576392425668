import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ChildActivationEnd, Router } from '@angular/router';
import { AuthService } from '@modules/auth/services';
import { filter } from 'rxjs/operators';
import { UserService } from '../modules/auth/services/user.service';

@Component({
    selector: 'app-root',
    template: `
        <sbpro-toast-view class="ngb-toasts" aria-live="polite" aria-atomic="true"></sbpro-toast-view>
        <fina-news-view></fina-news-view>
        <router-outlet></router-outlet>
    `,
})
export class AppComponent implements OnInit {
    constructor(public router: Router, private titleService: Title, private authService: AuthService, private userService: UserService) {
        this.router.events
            .pipe(filter((event) => event instanceof ChildActivationEnd))
            .subscribe((event) => {
                let snapshot = (event as ChildActivationEnd).snapshot;

                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                this.titleService.setTitle(snapshot.data.title || 'FINA Business');
            });
    }

    ngOnInit(): void {
        this.authService.autoLogin();
        document.body.classList.add('notranslate');
    }
}
