import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CopyToClipboard, CopyToClipboardOptions } from '@common/models';
import copyToClipboard from 'copy-to-clipboard';
import { Observable } from 'rxjs';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    _window: Window;
    parse: JSON['parse'];
    stringify: JSON['stringify'];
    localStorage: Storage;
    _copyToClipboard: CopyToClipboard;
    decimalStep: number = 0.00001;

    constructor(private http: HttpClient) {
        this._window = window;
        this.parse = JSON.parse;
        this.stringify = JSON.stringify;
        this.localStorage = localStorage;
        this._copyToClipboard = copyToClipboard;
    }

    get window(): Window {
        return this._window;
    }
    get version$(): Observable<string> {
        return this.http.get('/assets/version', { responseType: 'text' });
    }

    getStoredObject<T>(objectName: string): T | undefined {
        const objectString = this.localStorage.getItem(objectName);
        if (!objectString) {
            return undefined;
        }
        return this.parse(objectString) as T;
    }

    storeObject(objectName: string, objectToStore: {}): void {
        this.localStorage.setItem(objectName, this.stringify(objectToStore));
    }

    copyToClipboard(text: string, options?: CopyToClipboardOptions) {
        this._copyToClipboard(text, options);
    }

    getTextForExcel(value: string): string {
        return value || '';
    }

    getNumberForExcel(value: number): number {
        return value || null;
    }

    getDatesForExcel(date: any): string {
        return date ? moment(date).format('MM-DD-YYYY h:mm a') : '';
    }

    getHigherNumber(a: number, b: number): number {
        return a > b ? a : b;
    }
    
    // Se usa para redondear un número y tomar sólo 5 decimales
    toFixed = (value: number): number => {
        return this.decimalAdjust(value, -5);
    }

    // Ajuste decimal de un número.
    private decimalAdjust(value: any, exp: any) {
        // Si el exp no está definido o es cero...
        if (typeof exp === "undefined" || +exp === 0) {
            return Math.round(value);
        }
        value = +value;
        exp = +exp;
        // Si el valor no es un número o el exp no es un entero...
        if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
            return NaN;
        }
        // Shift
        value = value.toString().split("e");
        value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
        // Shift back
        value = value.toString().split("e");
        return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
    }
}
