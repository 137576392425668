import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@modules/auth/services';
import { PosthogService } from '@common/services';
import { SideNavItems, SideNavSection } from '@modules/navigation/models';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';
import { GoTo } from '@modules/dashboard/models/dashboard.model';
import Intercom from '@intercom/messenger-js-sdk';

@Component({
    selector: 'sb-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
    @Input() sidenavStyle!: string;
    @Input() sideNavItems!: SideNavItems;
    @Input() sideNavSections!: SideNavSection[];
    
    goTo: GoTo = {
        title: 'Nueva venta',
        link: '/pos/direct-sales'
    }

    subscription: Subscription = new Subscription();
    routeDataSubscription!: Subscription;

    constructor(public navigationService: NavigationService, public userService: UserService, public posthogService: PosthogService) {}

    ngOnInit() {

        Intercom({
            app_id: 't2hw46qi',
            user_id: this.userService.user$.getValue()._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name:this.userService.user$.getValue().name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: this.userService.user$.getValue().email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            company: this.userService.user$.getValue().tenantId,
            // created_at: this.userService.user$.getValue().createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
          });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    posthogTrack() {
        this.posthogService.posthogCapture('sale_opened sale', {lugar: "sidenav-top"} )
    }
}
